<template>
  <Header></Header>  
  <div class="main-height">
    <section class="activation">
      <div class="container">
        <div class="inner-container">
          <div class="row">
            <div class="col-12">
              <p class="mb-0">
                Step <span class="fw-bold">3</span> of <span class="fw-bold">3</span>
              </p>
            </div>
          </div>
  
          <div class="row">
            <div class="col-12 mt-2">
              <h1 class="fw-bold mt-0">
                Check your email for activation code
              </h1>
            </div>
          </div>
  
          <div class="row">
            <div class="col-12">
              <div class="mt-2">
                <p>
                  A trial activation code has been sent at your email address. Please enter it below
                  to activate your trial. Please also check your junk/spam box.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <form>
                <div class="register-form">
                  <div class="input-group">
                    <input required="" type="text" name="text" autocomplete="off" class="input" v-model="trialCode" @keyup.enter="doActivateTrial">
                    <label class="user-label">Trial Activation Code</label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div v-if="error.length" class="alert-danger mt-2 text-danger">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
            {{ error }}
          </div>
          
          <div class="row">
            <div class="col-12 mt-4">
              <button class="btn text-white w-100 rounded-1" @click="doActivateTrial">
              <span v-show="!loading">Next</span>
              <div v-show="loading" class="button-loading">
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  
  <Footer></Footer>

</template>

<script>
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AuthService from "@/services/auth-service";
import PlanService from "@/services/plan-service";

export default defineComponent({
  name: "Trial Activation",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      error: '',
      trialCode: '',
      loading: false
    }
  },
  methods: {
    
    doSignOut() {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    },
    doActivateTrial() {
      if (this.loading) return;
      this.loading = true;
      if (this.trialCode != null && this.trialCode != '') {
        PlanService.validateTrialActivationCode(this.trialCode).then((result) => {
          if (result) {
            this.$router.push('/trial-activation-success')
          } else {
            this.error = 'Invalid code. Please re-enter code.';
            this.loading = false;
          }
        }).catch((error) => {
          this.error = 'Error: ' + error
        })
      } else {
        this.error = "Please enter activation code";
        this.loading = false;
      }
    }
  },
  beforeMount() {
    // prevent direct navigation bugs
    if (localStorage.getItem('userProfile') == null) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    }
  }
})
</script>

<style>
@import '../assets/style.css';
</style>